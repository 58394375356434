import classes from './RoundedBorderCard.module.css';
import { useLanguage } from "../../../store/language-context";


const RoundedBorderCard = (props) => {
    const { icon, label, value, image, color, imo } = props;
    const { language } = useLanguage();

    return (
        <div className={classes.card}>
            <div style={{ backgroundColor: color }} className={classes['image-container']}>
                <img className={classes['main-image']} src={image} alt={label} />
            </div>
            <div className={classes['text-container']}>
                <p className={classes.label}>{label}</p>
                <div className={classes.value}>
                    <span className={classes.value}>{value}</span>&nbsp;
                    {imo && <span className={classes.value}>{imo}</span>}
                </div>
            </div>
            {icon &&
                <img
                    className={classes.icon}
                    src={icon}
                    alt={label}
                    style={{
                        transform: language === 'ar' ? 'scaleX(-1)' : 'none',
                        right: language === 'ar' ? 'auto' : 'none',
                        left: language === 'ar' ? '.5rem' : 'none',
                    }}
                />
            }
        </div>
    );
}

export default RoundedBorderCard;
