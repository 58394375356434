import classes from './HeaderSvg.module.css';

const HeaderSvg = () => {

    return (
        <svg className={classes['svg-style']} xmlns="http://www.w3.org/2000/svg" width="997" height="1095"
             viewBox="0 0 997 1095" fill="none">
            <g filter="url(#filter0_f_419_25)">
                <path
                    d="M170.789 -599.386C115.248 -614.268 -12.7137 85.4557 -12.7137 85.4557C-12.7137 85.4557 -251.757 755.415 -196.216 770.297C182.011 871.643 570.782 647.186 672.128 268.958C773.473 -109.269 549.017 -498.04 170.789 -599.386Z"
                    fill="url(#paint0_radial_419_25)" fillOpacity="0.2"/>
            </g>
            <defs>
                <filter id="filter0_f_419_25" x="-504.586" y="-899.62" width="1501.05" height="1994.25"
                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_419_25"/>
                </filter>
                <radialGradient id="paint0_radial_419_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(-12.7139 85.4555) rotate(-168.956) scale(658.392 658.391)">
                    <stop stopColor="#638FE7"/>
                    <stop offset="1" stopColor="#B7E4C1"/>
                </radialGradient>
            </defs>
        </svg>
    );
}

export default HeaderSvg;
