import classes from './App.module.css';
import { useTranslation } from 'react-i18next';
import { useLanguage } from "./store/language-context";
import Header from "./Components/Layout/Header";
import { useState } from "react";
import FirstPage from "./Components/Layout/FirstPage/FirstPage";
import SecondPage from "./Components/Layout/SecondPage/SecondPage";
import ThirdPage from "./Components/Layout/ThirdPage/ThirdPage";
import Footer from "./Components/Layout/Footer";
import HeaderSvg from "./assets/HeaderSvg";
import mobileHeader from "./assets/mobile-header.svg";
import FooterPng from "./assets/footer-bg.png";

function App() {
    const { t } = useTranslation();
    const { language, direction } = useLanguage();
    document.title = t('app.title');
    document.documentElement.lang = language;

    const [isMenuOpen, setIsOpenMenu] = useState(false)

    const handleToggleMenu = () => {
        setIsOpenMenu((prevState) => !prevState)
    }

    return (

        <>
            {/*{isLoading.current && <div className="loader">*/}
            {/*    <div className="box"></div>*/}
            {/*</div>}*/}

            <div
                className={`${classes.container} ${isMenuOpen ? classes['container-opened-menu'] : ''}`}
                style={{ direction }}>
                <div className={classes.header}>
                    <div className={classes['desktop-only']}>
                        {language === 'ar' ? (
                            <div className={classes['header-svg-ar']}>
                                <HeaderSvg />
                            </div>
                        ) : (
                            <HeaderSvg />
                        )}
                    </div>

                    <img
                        className={`${classes['mobile-header']} ${classes['mobile-only']}`}
                        src={mobileHeader}
                        alt="background"
                        style={{
                            transform: language === 'ar' ? 'scaleX(-1)' : 'none',
                            right: language === 'ar' ? '0' : 'auto',
                        }}
                    />

                    <div className={classes['header-container']}>
                        <Header isMenuOpen={isMenuOpen} onToggleMenu={handleToggleMenu} />
                    </div>
                </div>

                <div className={classes.main}>
                    {!isMenuOpen && (<>
                        <FirstPage />
                        <SecondPage />
                        <ThirdPage />
                    </>)}
                </div>

                {!isMenuOpen && (<>
                    <div className={classes.footer}>
                        <Footer />
                    </div>
                </>)}
                {!isMenuOpen && (<>
                    <img
                        className={classes['footer-bg']}
                        src={FooterPng}
                        alt="footer png"
                        style={{
                            transform: language === 'ar' ? 'scaleX(-1)' : 'none',
                            right: language === 'ar' ? '0' : 'auto',
                        }}
                    />
                </>)}
            </div>
        </>
    );
}

export default App;
