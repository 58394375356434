import classes from './Call.module.css';
import {useTranslation} from "react-i18next";
import Button from "../../UI/Button";
import CallsBg from '../../../assets/Calls-bg.svg';

const Call = () => {
    const {t} = useTranslation();

    return (
        <div className={classes.container}>
            <img className={classes['svg-bg']} src={CallsBg} alt=""/>
            <div className={classes['lines-container']}>
                <div className={classes.line}>
                    <span className={classes['calls-span']}>{t('call.title.1')}&nbsp;</span>
                </div>
                <div className={classes.line}>
                <span className={classes['calls-span']}>
                {t('call.title.2')}
                </span>
                    <span className={`${classes.bold} ${classes['calls-span']}`}>
                {t('call.title.3')}
                </span>
                </div>
            </div>
            <Button>{t('call.button')}</Button>
        </div>)
}

export default Call;
