import classes from './NumberItem.module.css'

const NumberItem = (props) => {
    const {img, value, title} = props
    return (<div className={classes.container}>
        <img className={classes['bg-img']} src={img} alt="bg"/>
        <span className={classes.value}>{value}</span>
        <div className={classes['title-wrapper']}>
            <span className={classes.title}>{title[0]}</span>
            <span className={classes['bold-title']}>{title[1]}</span>
        </div>
    </div>)
}

export default NumberItem
