import classes from './Header.module.css';
import Button from "../UI/Button";
import myImage from '../../assets/Full logo LG.png';
import menu from '../../assets/menu.svg';
import closeMenu from '../../assets/close-menu.svg';
import {useTranslation} from "react-i18next";
import Social from "../UI/Social";
import {useLanguage} from "../../store/language-context";

const Header = (props) => {
    const {t} = useTranslation();
    const {language} = useLanguage();

    const isMenuOpen = props.isMenuOpen
    const handleToggleMenu = () => {
        props.onToggleMenu()
    }

    return (
        <div className={`${classes.header} ${isMenuOpen ? classes['opened-side'] : ''}`}>
            <div className={classes['icon-container']}>
                <img className={classes['header-logo']} src={myImage} alt=""/>
                {!isMenuOpen && (<img
                    onClick={handleToggleMenu}
                    className={classes['menu-icon']}
                    src={menu}
                    alt=""
                    style={{
                        transform: language === 'ar' ? 'scaleX(-1)' : 'none',
                        right: language === 'ar' ? '0' : 'auto',
                    }}
                />)}
                {isMenuOpen && (
                    <img onClick={handleToggleMenu} className={classes['menu-icon']} src={closeMenu} alt=""/>)}
            </div>
            <div className={`${classes['header-links-container']} ${isMenuOpen ? classes['show-flex'] : ''}`}>
                <div className={classes['header-links']}>
                    <span>
                        <a
                            rel="noreferrer"
                            href="#steps">
                        {t('about')}
                        </a>
                    </span>
                    <span>
                        <a
                            rel="noreferrer"
                            href="/#">
                        {t('prices')}
                        </a>
                    </span>
                    <span>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://43us9n0vsgt.typeform.com/to/SoDXgTpg">
                            {t('careers')}
                        </a>
                        </span>
                </div>
            </div>
            <div className={classes['signup-button']}>
                <Button>
                    <a
                        target="_blank"
                        href=" https://43us9n0vsgt.typeform.com/to/QDGWxlgl">
                        {t('contact us')}
                    </a>
                </Button>
            </div>
            <div className={`${classes['divider']}  ${isMenuOpen ? classes['show-flex'] : ''}`}></div>

            <div className={`${classes['calls-title']}  ${isMenuOpen ? classes['show-block'] : ''}`}>
                <p>
                    <span className={classes['call-span']}>
                        {t('call.title.1')}
                    </span>
                </p>
                <p>
                    <span className={classes['call-span']}>
                        {t('call.title.2')}
                    </span>
                    <span className={`${classes.bold} ${classes['call-span']}`}>
                        {t('call.title.3')}
                    </span>
                </p>
            </div>
            <div
                className={`${classes['calls-button']}  ${isMenuOpen ? classes['show-flex'] : ''}`}>
                <Button>{t('call.button')}</Button>
            </div>

            <div className={`${classes['social-container']}  ${isMenuOpen ? classes['show-flex'] : ''}`}>
                <Social/>
            </div>

        </div>
    );
}

export default Header;
