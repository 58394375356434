import {useTranslation} from "react-i18next";
import classes from "./TrustedBy.module.css";
import img1 from "../../../assets/جامعة الملك عبدالله للعلوم و التقنية.svg";
import img2 from "../../../assets/ثقة.svg";
import img3 from "../../../assets/الهيئة العامة للطيران المدني.svg";
import img4 from "../../../assets/جامعة الامير سلطان.svg";
import img5 from "../../../assets/الهيئة العامة للغذاء والدواء.svg";
import img6 from "../../../assets/stc.svg";
import img7 from "../../../assets/مستشفي الملك فيصل التخصصي و مركز الأبحاث.svg";
import img8 from "../../../assets/الهيئة العامة للترفيه.svg";
import img9 from "../../../assets/وزارة الإسكان.svg";
import img10 from "../../../assets/المركز الوطني للفعاليات.svg";


const TrustedBy = () => {
    const {t} = useTranslation();

    const images = [
        {
            src: img1,
            alt: 'جامعة الملك عبدالله للعلوم و التقنية'
        },
        {
            src: img2,
            alt: 'ثقة'
        },
        {
            src: img3,
            alt: 'الهيئة العامة للطيران المدني'
        },
        {
            src: img4,
            alt: 'جامعة الأمير سلطان'
        },
        {
            src: img5,
            alt: 'الهيئة العامة للغذاء والدواء'
        },
        {
            src: img6,
            alt: 'STC'
        },
        {
            src: img7,
            alt: 'مستشفي الملك فيصل التخصصي و مركز الأبحاث'
        },
        {
            src: img8,
            alt: 'الهيئة العامة للترفيه'
        },
        {
            src: img9,
            alt: 'وزارة الإسكان'
        },
        {
            src: img10,
            alt: 'المركز الوطني للفعاليات'
        },
    ]

    return (<>
        <div className={classes.title}>{t('trustedBy')}</div>
        <div className={classes.container}>{
            images.map((img) => {
                return <img key={img.alt} className={classes.image} src={img.src} alt={img.alt}/>
            })
        }</div>
    </>)
}
export default TrustedBy;
