import classes from "./Intro.module.css";
import myImage from "../../../assets/image-khaled-black-and-white.png";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../store/language-context";

const Intro = () => {

    const { t } = useTranslation();
    const { language } = useLanguage();

    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <div
                    className={classes.title}
                    style={{ fontSize: language === 'ar' ? '1.875rem' : 'none' }}
                >
                    <span>
                        {t('i am your customer')}
                    </span>
                    <span
                        className={classes.name}
                        style={{ fontSize: language === 'ar' ? '1.875rem' : 'none' }}
                    >
                        {t('Khaled')}
                    </span>
                </div>
                <div className={`${classes.title} ${classes['sub-title']}`}>
                    {t('do you know me?')}
                </div>
            </div>
            <img
                className={classes['black-and-white-img']}
                src={myImage}
                alt="black-&-white-img"
                style={{
                    transform: language === 'ar' ? 'scaleX(-1)' : 'none',
                    right: language === 'ar' ? 'auto' : 'none',
                    left: language === 'ar' ? '-2rem' : 'auto',
                }}
            />


        </div>
    )
}


export default Intro;
