import classes from './Accordion.module.css';
import AccordionItem from "./AccordionItem";

const AccordionList = ({ accordion_list }) => {


    return (
        <div className={classes["container"]}>

            {accordion_list.map((item, index) => {
                return (
                    <div className={classes["item"]}>
                        <AccordionItem key={index} header={item.header} content={item.content} />
                        <hr className={classes["divider"]} />
                    </div>
                )
            })}

        </div>
    )
}

export default AccordionList