import BorderlessCard from "../../UI/BorderlessCard";
import classes from "./CardsList.module.css";
import myImage from "../../../assets/image-khaled-colored.png";
import bgImage from "../../../assets/bg-image.png";
import icon1 from "../../../assets/😄.png";
import icon2 from "../../../assets/♥️.png";
import icon3 from "../../../assets/🇸🇦.png";
import icon4 from "../../../assets/🧔🏻_♂️.png";
import icon5 from "../../../assets/💍.png";
import icon6 from "../../../assets/🧑🏻_🎓.png";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../store/language-context";

const CardsList = () => {
    const { t } = useTranslation();
    const { language } = useLanguage();

    const cards = [
        {
            id: 1,
            icon: icon1,
            value: "10",
            label: t('card1')
        },
        {
            id: 2,
            icon: icon2,
            value: "45%",
            label: t('card2')
        },
        {
            id: 3,
            icon: icon3,
            value: "25-34",
            label: t('card3')
        },
        {
            id: 4,
            icon: icon4,
            value: t("saudi"),
            label: t('card4')
        },
        {
            id: 5,
            icon: icon5,
            value: t("single"),
            label: t('card5')
        },
        {
            id: 6,
            icon: icon6,
            value: t("bachelor"),
            label: t('card6')
        },
    ]
    return (
        <div className={classes.container}>

            <div className={classes['cards-list']}>
                {cards.map(card => {
                    return (<BorderlessCard
                        key={card.id}
                        icon={card.icon}
                        value={card.value}
                        label={card.label} />)
                })
                }
            </div>

            <div
                className={classes['image-container']}
                style={{
                    transform: language === 'ar' ? 'scaleX(-1)' : 'none',
                    right: language === 'ar' ? '0' : 'auto',
                }}>
                <img className={classes['bg-image']} src={bgImage} alt="bg" />
                <img className={classes['main-image']} src={myImage} alt="khaled" />
            </div>

        </div>
    )
}
export default CardsList;
