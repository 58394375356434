import {useTranslation} from "react-i18next";
import classes from "./Bar.module.css";

const Bar = () => {
    const {t} = useTranslation();
    return (
        <div className={classes.container}>
            <span className={classes.text}>{t('bar.saudi')}</span>
            <span className={`${classes.dot} ${classes.yellow}`}></span>
            <span className={classes.text}>{t('bar.Integration')}</span>
            <span className={`${classes.dot} ${classes.orange}`}></span>
            <span className={classes.text}>{t('bar.Customization')}</span>
            <span className={`${classes.dot} ${classes.blue}`}></span>
            <span className={classes.text}>{t('bar.Templates')}</span>
            <span className={`${classes.dot} ${classes.purple}`}></span>
            <span className={classes.text}>{t('bar.Consultations')}</span>
            <span className={`${classes.dot} ${classes.green}`}></span>
            <span className={classes.text}>{t('bar.LocalDatabase')}</span>
            <span className={`${classes.dot} ${classes.yellow}`}></span>
            <span className={classes.text}>{t('bar.Researchers')}</span>
        </div>
    )
}

export default Bar;
