import classes from './Steps.module.css';
import {useTranslation} from "react-i18next";
import desktopImg from '../../../assets/steps-desktop-bg.png';
import MobileImg from '../../../assets/steps-mobile-bg-Img.svg';
import ticketsImg from '../../../assets/tickets.svg';
import car from '../../../assets/car.png';
import flag from '../../../assets/🚩.png';
import clock from '../../../assets/⏱.png';
import seats from '../../../assets/seats 1.png';
import restaurant from '../../../assets/restaurant 1.png';
import lavatory from '../../../assets/lavatory 1.png';
import desk from '../../../assets/check-in-desk 1.png';
import mob from '../../../assets/mob.svg';
import styles from '../../../App.module.css';
import React from "react";
import RoundedBorderCard from "./RoundedBorderCard";
import {useLanguage} from "../../../store/language-context";

const Steps = () => {
    const {t} = useTranslation();
    const {language} = useLanguage();

    const stepsContent = [
        {
            icon: mob,
            label: t('steps.tickets'),
            image: ticketsImg,
            value: '50%',
            color: '#FF4B00',
            imo: '😀'
        },
        {

            label: t('steps.parking'),
            image: car,
            value: '30%',
            color: '#1C4FB5',
            imo: '😐'
        },
        {
            icon: flag,
            label: t('steps.checkin'),
            image: desk,
            value: '60%',
            color: '#4ABC64',
            imo: '🤩'
        },
        {
            label: t('steps.facilities'),
            image: lavatory,
            value: '20%',
            color: '#FFF',
            imo: '☹️'
        },
        {
            icon: clock,
            label: t('steps.restaurant'),
            image: restaurant,
            value: '86%',
            color: '#DFC4FF',
            imo: '😍'
        },
        {
            label: t('steps.seating'),
            image: seats,
            value: '40%',
            color: '#FAFF3F',
            imo: '😕'
        }
    ];

    return (<div className={classes.container} id={'steps'}>
        <div className={classes.title}>
            <span className={classes.bold}>{t('steps.title.1')}</span>
            <span className={classes.normal}>{t('steps.title.2')}</span>
            <span className={classes.bold}>{t('steps.title.3')}</span>
        </div>

        <div className={`${classes["image-container"]}`}>
            <img
                className={`${styles['desktop-only']} ${classes['bg-img']} ${classes['hidden-in-smaller-screens']}`}
                src={desktopImg}
                alt="bg"
                style={{
                    transform: language === 'ar' ? 'scaleX(-1)' : 'none',
                    right: language === 'ar' ? '5rem' : 'auto',
                }}
            />
            <img
                className={`${styles['mobile-only']}  ${classes['bg-img']}`}
                src={MobileImg}
                alt="bg"
                style={{
                    transform: language === 'ar' ? 'scaleX(-1)' : 'none',
                }}
            />

            {stepsContent.map((item, index) => {
                return <RoundedBorderCard
                    key={index}
                    icon={item.icon}
                    label={item.label}
                    image={item.image}
                    value={item.value}
                    color={item.color}
                    imo={item.imo}
                />
            })}
        </div>
    </div>)
}

export default Steps;
