import { useTranslation } from "react-i18next";
import classes from "./Tools.module.css";
import Survey from "./Survey";
import bg from './../../../assets/tools-bg.svg';
import Dashboard from "./Dashboard";
import { useLanguage } from "../../../store/language-context";

const Tools = () => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    console.log("DEBUG:Tools:language: ",useLanguage());

    return (<div className={classes.container}>
        <h1 className={classes.title}>{t('tool.title')}</h1>
        <img
            className={classes.bg}
            src={bg}
            alt="background"
            style={{
                transform: language === 'ar' ? 'scaleX(-1)' : 'none',
                right: language === 'ar' ? '0' : 'auto',
            }}
        />
        <Survey />
        <Dashboard />
    </div>)
}

export default Tools
