import classes from './Demo.module.css';
import {useTranslation} from "react-i18next";
import play from "../../../assets/play.svg";
import bg from "../../../assets/demo-bg.png";
import Button from "../../UI/Button";

const Demo = () => {
    const {t} = useTranslation();
    return (
        <div className={classes.container}>
            <div className={classes['bg-container']}>
                <img src={bg} alt="bg" className={classes.bg}/>
            </div>
            <h1 className={classes.title}>{t('demo.title')}</h1>
            <img className={classes.play} src={play} alt="play"/>
            <Button className={classes.btn}>
                {t('demo.btn')}
            </Button>
        </div>
    )
}

export default Demo;
