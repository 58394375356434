import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';
import {I18nextProvider} from 'react-i18next';
import {LanguageProvider} from "./store/language-context";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        {/*<React.Suspense fallback="loading...">*/}
        <LanguageProvider>
            <I18nextProvider i18n={i18n}>
                <App/>
            </I18nextProvider>
        </LanguageProvider>
        {/*</React.Suspense>*/}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
