import Steps from "./Steps";
import Tools from "./Tools";
import Bar from "./Bar";

const SecondPage = () => {
    return (<>
        <Steps/>
        <Tools/>
        <Bar/>
    </>)
}

export default SecondPage;
