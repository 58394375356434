import classes from './Social.module.css';
import linkedin from '../../assets/LinkedIN.png';
import instagram from '../../assets/Instagram.png';
import twitter from '../../assets/Twitter.png';

const Social = () => {
    return (
        <div className={classes['container']}>
            <a href="https://www.linkedin.com/company/sadaacx/about/?viewAsMember=true"
                className={classes['social-item']}
                target="_blank" rel="noreferrer">
                <img src={linkedin} alt="Linkedin" />
            </a>
            <a href="https://www.instagram.com/saadacx/"
                className={classes['social-item']}
                target="_blank" rel="noreferrer">
                <img src={instagram} alt="Instagram" />
            </a>
            <a href="https://twitter.com/saadaCX"
                className={classes['social-item']}
                target="_blank" rel="noreferrer">
                <img src={twitter} alt="Twitter" />
            </a>
        </div>
    );
}


export default Social;