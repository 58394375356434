import classes from "./Numbers.module.css";
import {useTranslation} from "react-i18next";
import NumberItem from "./NumberItem";
import img1 from "../../../assets/image 1.svg";
import img2 from "../../../assets/image 2.svg";
import img3 from "../../../assets/image 3.svg";

const Numbers = () => {
    const {t} = useTranslation();
    const items = [
        {
            img: img1,
            value: t('numbers.items.value.1'),
            title: [
                t('numbers.items.title.11'),
                t('numbers.items.title.12')
            ]
        },
        {
            img: img2,
            value: t('numbers.items.value.2'),
            title: [
                t('numbers.items.title.21'),
                t('numbers.items.title.22')
            ]
        },
        {
            img: img3,
            value: t('numbers.items.value.3'),
            title: [
                t('numbers.items.title.31'),
                t('numbers.items.title.32')
            ]
        }

    ];

    return (<>
        <div className={classes.title}>
            <span>{t('numbers.title.1')}</span>
            <span className={classes['sub-title']}>{t('numbers.title.2')}</span>
        </div>
        <div className={classes.numbers}>
            {items.map(item => {
                return <NumberItem
                    key={item.value}
                    img={item.img}
                    value={item.value}
                    title={item.title}
                />
            })}
        </div>
    </>)
}

export default Numbers;
