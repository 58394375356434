import classes from './Footer.module.css';
import logo from '../../assets/Full logo LG.png';
import Social from '../UI/Social';
import {useLanguage} from '../../store/language-context';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import arabicFlag from '../../assets/arabic-lang-flag.svg';
import englishFlag from '../../assets/eng-lang-flag.svg';

// Make footer fixed at bottom
// Apply mobile styles 'for @media (max-width: 768px) {'

const Footer = () => {
    const {t} = useTranslation();
    const {language, changeLanguage} = useLanguage();

    const handleChangeLanguage = () => {
        const newLanguage = language === 'en' ? 'ar' : 'en';
        changeLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
        document.title = t('app.title');
        document.documentElement.lang = language;
        document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
        // window.scroll({top: 0, behavior: 'smooth'})
    };

    const links1 = [
        {text: t('careers'), href: 'https://43us9n0vsgt.typeform.com/to/SoDXgTpg'},
        {text: t('contact us'), href: 'https://43us9n0vsgt.typeform.com/to/QDGWxlgl'},
    ];
    const links2 = [
        {text: t('about'), href: '#steps'},
        {text: t('prices'), href: '#'},
    ];

    return (
        <div className={classes.footer}>
            <div className={classes.container}>
                <div className={classes['logo-container']}>
                    <img className={classes.logo} src={logo} alt="Footer logo"/>
                </div>
                <div className={classes['links-container']}>
                    <div className={classes['link-row']}>
                        {links1.map((link, index) => (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                key={index}
                                className={classes.link}
                                href={link.href}>
                                {t(link.text)}
                            </a>
                        ))}
                    </div>
                    <div className={classes['link-row']}>
                        {links2.map((link, index) => (
                            <a key={index} className={classes.link} href={link.href}>
                                {t(link.text)}
                            </a>
                        ))}
                    </div>

                </div>

                <div className={classes['social-lang-container']}>
                    <button
                        className={classes['language-switch']}
                        onClick={handleChangeLanguage}

                    >
                        <div className={classes["language-text"]}>
                            <p className={classes["language"]}
                               style={
                                   language === 'ar' ? {
                                       paddingBottom: '',
                                       lineHeight: '32px'
                                   } : {paddingBottom: '.35rem'}
                               }
                            >
                                {language === 'ar' ? 'En' : 'ع'}
                            </p>
                        </div>

                        <img className={classes["language-icon"]} alt="langauge"
                             src={
                                 language === 'ar' ? englishFlag : arabicFlag
                             }
                        />
                    </button>

                    <Social/>
                </div>
            </div>
        </div>


    );
}

export default Footer;
