import classes from './BorderlessCard.module.css';

const BorderlessCard = ({icon, value, label}) => {

    return (
        <div className={classes.container}>
            <img className={classes.icon} src={icon} alt={label}/>
            <p className={classes.value}>{value}</p>
            <p className={classes.label}>{label}</p>
        </div>
    )
}

export default BorderlessCard;
