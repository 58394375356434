import classes from "./Survey.module.css";
import phone1 from "../../../assets/phone-1.png";
import phone2 from "../../../assets/phone-2.png";
import {useTranslation} from "react-i18next";

const Survey = () => {
    const {t} = useTranslation();


    return (<div className={classes['survey-tool-container']}>
        <div className={classes['image-container']}>
            <div>
                <img className={classes.phone} src={phone1} alt="phone"/>
                <p className={classes['sub-photo']}>{t('tool.subPhoto1')}</p>
            </div>

            <div>
                <img className={classes.phone} src={phone2} alt="phone"/>
                <p className={classes['sub-photo']}>{t('tool.subPhoto2')}</p>
            </div>
        </div>
        <div className={classes.content}>

            <div className={classes['sub-title']}>
                {t('tool.subtitle')}
            </div>

            <p className={classes.desc}>{t('tool.p1')}</p>
            <p className={classes.desc}>{t('tool.p2')}</p>
            <p className={classes.desc}>{t('tool.p3')}</p>
        </div>
    </div>)
}

export default Survey;
