import classes from './Faq.module.css';
import Accordion from "../../UI/Accordion";
import { useTranslation } from 'react-i18next';
import FaqBg from '../../../assets/faq-bg.svg';

const Faq = () => {
    const { t } = useTranslation();

    const accordion_list = [
        {
            "header": t('faq.sample.header'),
            "content": t('faq.sample.content')
        },
        {
            "header": t('faq.sample.header'),
            "content": t('faq.sample.content')
        },
        {
            "header": t('faq.sample.header'),
            "content": t('faq.sample.content')
        },
        {
            "header": t('faq.sample.header'),
            "content": t('faq.sample.content')
        },
        {
            "header": t('faq.sample.header'),
            "content": t('faq.sample.content')
        }
    ];

    return (
        <>
            <div className={classes["container"]}>
                <img className={classes['svg-bg']} src={FaqBg} alt="FAQ background svg" />
                <div className={classes["wrapper"]}>
                    <div className={classes["heading"]}>
                        {t('faq.title')}
                    </div>
                    <div className={classes["list-container"]}>
                        <Accordion accordion_list={accordion_list} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default Faq