import classes from './AccordionItem.module.css';
import React, {useEffect, useRef, useState} from 'react';
import AddIcon from '../../assets/add.svg';
import MinusIcon from '../../assets/minus.svg';

const Accordion = ({header, content}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);

    useEffect(() => {
        setContentHeight(contentRef.current.scrollHeight);
    }, [isOpen]);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`${classes.container} ${isOpen ? classes.active : ''}`}>
            <div className={classes["header-container"]} onClick={toggleAccordion}>
                <div className={classes["header"]}>
                    {header}
                </div>

                {isOpen ?
                    <img className={classes["action-icon"]} src={MinusIcon} alt="minus"/> :
                    <img className={classes["action-icon"]} src={AddIcon} alt="Add icon"/>
                }

            </div>

            <div
                className={classes["panel"]}
                style={{
                    maxHeight: isOpen ? `${contentHeight}px` : '0',
                    transition: `max-height ${isOpen ? '.1s' : '0.1s'} ease-out`,
                }}
            >
                <div
                    className={classes["content"]}
                    ref={contentRef}
                    style={{padding: '1rem'}}
                >
                    {content}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
