import CardsList from "./CardsList";
import Intro from "./Intro";
import Demo from "./Demo";
import SwiperContainer from "./SwiperContainer";


const FirstPage = () => {
    return (
        <>
            <Intro/>
            <CardsList/>
            <SwiperContainer/>
            <Demo/>
        </>
    )
}

export default FirstPage;
