import {useTranslation} from "react-i18next";
import classes from "./Dashboard.module.css";
import dashboard from "../../../assets/dashboard.png";

const Dashboard = () => {
    const {t} = useTranslation();

    return (<div className={classes.container}>
        <div className={classes.content}>
            <div className={classes.title}>
                {t('tool.dashboard.title')}
            </div>
            <div className={classes.desc}>{t('tool.dashboard.p1')}</div>
            <div className={classes.desc}>{t('tool.dashboard.p2')}</div>
        </div>
        <img className={classes.img} src={dashboard} alt="dashboard"/>
    </div>)
}
export default Dashboard;
