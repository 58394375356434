import React, { createContext, useContext, useState } from 'react';

const LanguageContext = createContext({
    language: 'en',
    direction: 'ltr',
    changeLanguage: () => {
    },
});

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') || 'en');

    const changeLanguage = (lng) => {
        setLanguage(lng);
    };

    const getDirection = () => {
        // Assuming Arabic should be RTL, and other languages LTR
        return language === 'ar' ? 'rtl' : 'ltr';
    };

    return (
        <LanguageContext.Provider value={{ language, direction: getDirection(), changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};
