import classes from './ImpactItem.module.css';

const ImpactItem = (props) => {
    const { image, imageBg, icon, title, features } = props;

    return (
        <div className={classes["container"]}>
            <div className={classes["image-container"]}>
                <img className={classes["image-bg"]} alt="img bg" src={imageBg} />
                <img className={classes["image"]} alt="Item img" src={image} />
            </div>

            <div className={classes["body-container"]}>
                <div className={classes["desc-container"]}>
                    <p className={classes["title"]}>{title}</p>
                    <div className={classes["features-container"]}>
                        {features.map((featureItem, index) => (
                            <p className={classes["feature"]} key={featureItem}>{featureItem}</p>
                        ))}
                    </div>
                </div>
                <div className={classes["icon-container"]}>
                    <img className={classes["icon"]} src={icon} alt="Icon" />
                </div>

            </div>
        </div>
    )
}


export default ImpactItem