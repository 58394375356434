import 'swiper/css';
import 'swiper/css/thumbs';
import 'swiper/css/pagination';
import classes from './SwiperContainer.module.css';
import styles from '../../../App.module.css';
import image1 from "../../../assets/swiper1.png";
import image2 from "../../../assets/swiper2.png";
import image3 from "../../../assets/swiper3.png";
import image4 from "../../../assets/swiper4.png";
import image5 from "../../../assets/swiper5.png";
import {useTranslation} from "react-i18next";
import {Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";


const SwiperContainer = () => {
    const {t} = useTranslation();
    const swiperContent = [
        {
            id: 1,
            image: image1,
            title: t('swiper.swiper1.title'),
            description: t('swiper.swiper1.description'),
        },
        {
            id: 2,
            image: image2,
            title: t('swiper.swiper2.title'),
            description: t('swiper.swiper2.description'),
        },
        {
            id: 3,
            image: image3,
            title: t('swiper.swiper3.title'),
            description: t('swiper.swiper3.description'),
        },
        {
            id: 4,
            image: image4,
            title: t('swiper.swiper4.title'),
            description: t('swiper.swiper4.description'),
        },
        {
            id: 5,
            image: image5,
            title: t('swiper.swiper5.title'),
            description: t('swiper.swiper5.description'),
        }
    ]

    return (
        <>
            <div className={classes.paragraph}>{t('swiper title')}</div>
            <div className={styles['mobile-only']}>

                <Swiper
                    className={`${classes['swiper-wrapper']}`}
                    modules={[Pagination]}
                    spaceBetween={50}
                    slidesPerView={1}
                    pagination={{
                        enabled: true,
                        clickable: true,
                        bulletClass: classes.bullet,
                        bulletActiveClass: classes['active-bullet'],
                    }}
                >
                    {swiperContent.map(content => {
                            return (
                                <SwiperSlide key={content.id}>
                                    <div className={classes["swiper-slide"]}>
                                        <img className={classes.image} src={content.image} alt=""/>
                                        <div className={classes["slide-content"]}>
                                            <div className={classes.title}>
                                                {content.title}
                                            </div>
                                            <div className={classes.description}>
                                                {content.description}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        }
                    )}
                </Swiper>
            </div>
            <div className={styles['desktop-only']}>
                <div className={classes["swiper-container"]}>
                    {swiperContent.map(content => {
                            return (
                                <div key={content.id} className={classes["swiper-slide"]}>
                                    <img className={classes.image} src={content.image} alt=""/>
                                    <div className={classes["slide-content"]}>
                                        <div className={classes.title}>
                                            {content.title}
                                        </div>
                                        <div className={classes.description}>
                                            {content.description}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    )}
                </div>
            </div>
        </>
    )
}

export default SwiperContainer;
