import classes from './Impact.module.css';
import ImpactItem from "./ImpactItem";
import { useTranslation } from 'react-i18next';

import finance_image from "../../../assets/impact/finance_image.png";
import finance_image_bg from "../../../assets/impact/finance_ellipse.png";
import finance_icon from "../../../assets/impact/finance_icon.png";

import support_image from "../../../assets/impact/support_image.png";
import support_image_bg from "../../../assets/impact/support_ellipse.png";
import support_icon from "../../../assets/impact/support_icon.png";

import marketing_image from "../../../assets/impact/marketing_image.png";
import marketing_image_bg from "../../../assets/impact/marketing_ellipse.png";
import marketing_icon from "../../../assets/impact/marketing_icon.png";

import ux_image from "../../../assets/impact/ux_image.png";
import ux_image_bg from "../../../assets/impact/ux_ellipse.png";
import ux_icon from "../../../assets/impact/ux_icon.png";

import operation_image from "../../../assets/impact/operation_image.png";
import operation_image_bg from "../../../assets/impact/operation_ellipse.png";
import operation_icon from "../../../assets/impact/operation_icon.png";

const Impact = () => {
    const { t } = useTranslation();


    const impacts_list = [
        {
            "image": finance_image,
            "imageBg": finance_image_bg,
            "icon": finance_icon,
            "title": t("impact.cards.finance.title"),
            "features": [t("impact.cards.finance.feature1"), t("impact.cards.finance.feature2")]
        }
        ,
        {
            "image": support_image,
            "imageBg": support_image_bg,
            "icon": support_icon,
            "title": t("impact.cards.support.title"),
            "features": [t("impact.cards.support.feature1"), t("impact.cards.support.feature2")]
        },

        {
            "image": marketing_image,
            "imageBg": marketing_image_bg,
            "icon": marketing_icon,
            "title": t("impact.cards.marketing.title"),
            "features": [t("impact.cards.marketing.feature1"), t("impact.cards.marketing.feature2")]
        },
        {
            "image": ux_image,
            "imageBg": ux_image_bg,
            "icon": ux_icon,
            "title": t("impact.cards.ux.title"),
            "features": [t("impact.cards.ux.feature1"), t("impact.cards.ux.feature2")]
        },
        {
            "image": operation_image,
            "imageBg": operation_image_bg,
            "icon": operation_icon,
            "title": t("impact.cards.operation.title"),
            "features": [t("impact.cards.operation.feature1"), t("impact.cards.operation.feature2")]
        }
    ];

    return (
        <>
            <div className={classes["container"]}>

                <div className={classes["cards-container"]}>
                    <div className={classes["header-container"]}>
                        <p>
                            <span className={classes["title-bold"]}>{t("impact.title-bold")}</span>
                            <span className={classes["title-normal"]}> {t("impact.title-normal")}</span>
                        </p>
                    </div>

                    {impacts_list.map((item, index) =>
                        <div className={classes["card-item"]}>
                            <ImpactItem key={item.title} {...item} />
                        </div>)
                    }
                </div>
            </div>
        </>
    )
}

export default Impact