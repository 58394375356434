import Call from "./Call";
import Faq from "./Faq";
import Impact from "./Impact";
import Numbers from "./Numbers";
import TrustedBy from "./TrustedBy";

const ThirdPage = () => {

    return (
        <>
            <Impact />
            <Numbers/>
            <TrustedBy/>
            <Call/>
            <Faq/>
        </>)
}

export default ThirdPage;
